import React, { Component } from 'react'
import { Chip, Avatar, Box, Typography } from '@material-ui/core'

export default class Skills extends Component {

    handleClick = (e) => {
        console.log('Clicked')
    }

    render() {
        return (
            <Box className="chips" m={3} p={3}
                style={{
                    border: '3px inset #5EC4D0',
                    borderRadius: '25px',
                    backgroundColor: 'rgba(94, 139, 208, 0.90)', 
                    padding: '20px',
                    marginBottom: '10px'
                }}
            >
                <Typography variant="h2" style={{ padding: '10px', textAlign: 'center', margin: '10px' }}>Core Skills</Typography>
                <Chip 
                avatar={<Avatar src="https://html5hive.org/wp-content/uploads/2014/06/js_800x800.jpg"/>}
                label="JavaScript"
                variant="outlined"
                onClick={this.handleClick}
                style={{ 
                    fontSize: 23
                }}

                />
                <Chip 
                avatar={<Avatar src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoPhoZldr8xb_FQDHgmv5QOmH1PFkeumJjuw&usqp=CAU"/>}
                label="Node.js"
                variant="outlined"
                onClick={this.handleClick}
                style={{ 
                    fontSize: 23
                }}

                />
                <Chip 
                avatar={<Avatar src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsy5gDfp3WEouGv0hE3R_vpMliSDR9mGKwmg&usqp=CAU"/>}
                label="React"
                variant="outlined"
                onClick={this.handleClick}
                style={{ 
                    fontSize: 23
                }}

                />
                <Chip 
                avatar={<Avatar src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnLnvYN9nV3mW3svjJr_vdlRO6kcpswOUsPA&usqp=CAU"/>}
                label="Python"
                variant="outlined"
                onClick={this.handleClick}
                style={{ 
                    fontSize: 23
                }}

                />
                <Chip 
                avatar={<Avatar src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbOncq-zS9UoQxTu8nopTiqh66Iaxw19s0WA&usqp=CAU"/>}
                label="Git"
                variant="outlined"
                onClick={this.handleClick}
                style={{ 
                    fontSize: 23
                }}

                />
                <Chip 
                avatar={<Avatar src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlDBDosSHWJEb17OhtdepnOUKBY0Q6hyBrMA&usqp=CAU"/>}
                label="HTML and CSS"
                variant="outlined"
                onClick={this.handleClick}
                style={{ 
                    fontSize: 23
                }}

                />
                <Chip 
                avatar={<Avatar src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_dla3RfO9mXXt94C7h6aKvaoYEJI44OM9rQ&usqp=CAU"/>}
                label="Flask"
                variant="outlined"
                onClick={this.handleClick}
                style={{ 
                    fontSize: 23
                }}

                />
                <Chip 
                avatar={<Avatar src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRc3o04jAmDhBz2MsEpd3-82OLjukjW96FgVg&usqp=CAU"/>}
                label="SQL"
                variant="outlined"
                onClick={this.handleClick}
                style={{ 
                    fontSize: 23
                }}

                />
                <Chip 
                avatar={<Avatar src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKokIuxap5JKl9BL0ei9eZ6M2Ps1VkFKlfOg&usqp=CAU"/>}
                label="Java"
                variant="outlined"
                onClick={this.handleClick}
                style={{ 
                    fontSize: 23
                }}

                />
                <Chip 
                avatar={<Avatar src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQa4rbEYSavjGzezjwgdjxixbUJNFHi_2vz7A&usqp=CAU"/>}
                label="MongoDB"
                variant="outlined"
                onClick={this.handleClick}
                style={{ 
                    fontSize: 23
                }}

                />
                <Chip 
                avatar={<Avatar src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoPhoZldr8xb_FQDHgmv5QOmH1PFkeumJjuw&usqp=CAU"/>}
                label="Multer"
                variant="outlined"
                onClick={this.handleClick}
                style={{ 
                    fontSize: 23
                }}

                />
                <Chip 
                avatar={<Avatar src="https://vegibit.com/wp-content/uploads/2018/07/JSON-Web-Token-Authentication-With-Node.png"/>}
                label="JsonWebToken"
                variant="outlined"
                onClick={this.handleClick}
                style={{ 
                    fontSize: 23
                }}

                />
                <Chip 
                avatar={<Avatar src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoPhoZldr8xb_FQDHgmv5QOmH1PFkeumJjuw&usqp=CAU"/>}
                label="Express"
                variant="outlined"
                onClick={this.handleClick}
                style={{ 
                    fontSize: 23
                }}

                />
                <Chip 
                avatar={<Avatar src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Typescript_logo_2020.svg/1200px-Typescript_logo_2020.svg.png"/>}
                label="Typescript"
                variant="outlined"
                onClick={this.handleClick}
                style={{ 
                    fontSize: 23
                }}

                />
                
            </Box>
        )
    }
}
