import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typist from 'react-typist'
import About from './components/About'
import GitHub from './components/GitHub'
import Projects from './components/Projects'
import Resume from './components/Resume'
import Skills from './components/Skills'
import MoreAbout from './components/MoreAbout'
import Aos from 'aos'
import 'aos/dist/aos.css'

const useStyles = (theme) => ({

  root: {
    backgroundColor: "rgba(106, 94, 208, 0.85)",
    borderRadius: "25px",
    border: "3px inset rgba(94, 196, 208, 0.93)"
  }
})

export class App extends Component {
  
  componentDidMount() {
    
    Aos.init({ duration: 1500 })
  }
  
  render() {
    const { classes } = this.props
    return (
      <Container maxWidth="md" className={classes.root}>
        <Typist
          cursor={{show: false}}
          avgTypingDelay={100}
        >
        <Typist.Delay ms={500} />
          <h1
            style={{ 
              textAlign: 'center',
              fontFamily: 'monospace',
              fontSize: 50
            }}
          >Jacob Cutshall</h1>
          <Typist.Delay ms={300} />
          <h1
            style={{ 
              textAlign: 'center',
              fontFamily: 'monospace',
              fontSize: 40
            }}
          >Software Engineer</h1>
        </Typist>

        <GitHub />
        <About />
        <div
          data-aos="fade-up" 
          data-aos-delay="50" 
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
        >
          <Resume />
        </div>
        <div
          data-aos="fade-up" 
          data-aos-delay="100" 
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
        >
          <Projects />
        </div>
        <div 
          data-aos="fade-right" 
          data-aos-delay="100" 
          data-aos-offset="50"
          data-aos-easing="ease-in-sine"
        >
          <Skills />
        </div>
        <div data-aos="fade-left" data-aos-delay="100" data-aos-offset="50" data-aos-easing="ease-in-sine" >
          <MoreAbout />
        </div>
        
      </Container>
    )
  }
}

export default withStyles(useStyles)(App)
