import React from 'react'
import { Grid, Paper, Typography, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import gol from './gol.webp'


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: 3,
        
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.primary,
        backgroundColor: 'rgba(94, 139, 208, 0.85)'
    },
    
}))


export default function Projects() {
    const classes = useStyles()

    
    
    return (
        <div className={classes.root}>
            <Grid
                container 
                direction="row"
                justify="space-evenly"
                alignItems="center"
                spacing={2}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: 'space-evenly',
                    padding: "25px",
                    fontFamily: "fantasy"
                }}
            >


                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Link
                        href="https://gemini-media-inc.herokuapp.com/"
                        variant="body2"
                        target="_blank"
                        rel="noopener"
                        
                    >
                        <Paper className={classes.paper}>
                            <Typography variant="h5">Responsive landing page for Gemini Media, Inc.</Typography>
                            <Typography variant="body1"> Redesigned the landing page for Gemini Media, Inc. To be more responsive and accessible across all platforms. This is a pure HTML/CSS served from a Node.js server.</Typography>
                            
                        </Paper>
                    </Link>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Link
                        href="https://qualitybooks.brigittecutshall.com/"
                        variant="body2"
                        target="_blank"
                        rel="noopener"
                        
                    >
                        <Paper className={classes.paper}>
                            <Typography variant="h5">Quality Book Formula Landing page</Typography>
                            <Typography variant="body1"> Created a landing page for a new book coming out to teach readers about the steps to creating and publishing a good book! Has a free pdf users can download after they enter their email.</Typography>
                            
                        </Paper>
                    </Link>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Link
                                href="https://jpcutshall.github.io/game-of-life/"
                                variant="body2"
                                target="_blank"
                                rel="noopener"
                                
                            >
                        <Paper className={classes.paper}>
                            <Typography variant="h5">Game of life in JS</Typography>
                            <Typography variant="body1"> I recreated Conway's game of life using p5.js. </Typography>
                            <img 
                                height="100px" 
                                width="100px" 
                                src={gol} 
                                alt='conways game of life: cellular automata' 
                                style={{
                                    paddingTop: '10px'
                                }}
                                />
                            
                            </Paper>
                        </Link>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Link
                        href="https://github.com/jpcutshall/Jacob-s_MineSweeper"
                        variant="body2"
                        target="_blank"
                        rel="noopener"
                    >
                        <Paper className={classes.paper}>
                            <Typography variant="h5">MineSweeper using Jquery</Typography>
                            <Typography variant="body1"> This is my attempt at recreating the game minesweeper using only Jquery. The most difficult part about this project was implementing the logic for checking the squares that touch. </Typography>
                            
                        </Paper>
                    </Link>
                </Grid>

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Link
                                href="https://github.com/jpcutshall/SnapShotSki"
                                variant="body2"
                                target="_blank"
                                rel="noopener"
                            >
                        <Paper className={classes.paper}>
                            <Typography variant="h5">SnapShot Ski</Typography>
                            <Typography variant="body1"> This is a simple express.js and mongoose application for uploading and sharing images. </Typography>
                            
                        </Paper>
                    </Link>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Link
                        href="https://github.com/jpcutshall/Adventurers-Guide-Front-End"
                        variant="body2"
                        target="_blank"
                        rel="noopener"
                    >
                        <Paper className={classes.paper}>
                            <Typography variant="h5">Adventurers Guide Wiki</Typography>
                            <Typography variant="body1">This is a full stack MERN application utilizing google maps api and json web tokens. Its purpose is to share specific locations in the wilderness for anyone who enjoys exploring the outdoors.</Typography>
                            
                        </Paper>
                    </Link>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Link
                        href="https://jpcutshall.github.io/shopping-cart-react/index.html"
                        variant="body2"
                        target="_blank"
                        rel="noopener"
                    >
                        <Paper className={classes.paper}>
                            <Typography variant="h5">React with Typescript shopping cart app</Typography>
                            <Typography variant="body1"> This is my attempt at an online shopping website using a react.js with Typescript. </Typography>
                            
                    
                        </Paper>
                    </Link>
                </Grid>
                
            </Grid>
        </div>
    )
    
}
