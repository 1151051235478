import React, { Component } from 'react'
import { IconButton, Box } from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

export default class Resume extends Component {
    render() {
        return (
            <Box
                style={{
                border: '3px inset #5EC4D0',
                borderRadius: '25px',
                backgroundColor: 'rgba(94, 139, 208, 0.93)', 
                padding: '3px',
                textAlign: 'center',
                marginBottom: '8px'
                }}
            >
                <span style={{ fontFamily: 'monospace', fontSize: '2em'}}>Jacob's Resume <IconButton
                color="primary" aria-label="Download Resume"
                ><a href="/resume.pdf" download><CloudDownloadIcon /></a></IconButton> 
                </span>
            </Box>
        )
    }
}
