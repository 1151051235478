import React, { Component } from 'react'
import { Link, Box } from '@material-ui/core'



export default class GitHub extends Component {
    render() {
        return (
            <Box>
                <Box m={1} p={3}>
                    <Link
                        href="https://github.com/jpcutshall"
                        variant="body2"
                        target="_blank"
                        rel="noopener"
                        style={{ 
                            color: 'pink',
                            fontSize: '20px'
                        }}
                        
                    >
                        GitHub
                    </Link>
                -
                    <Link
                        href="https://www.linkedin.com/in/jpcutshall/"
                        variant="body2"
                        target="_blank"
                        rel="noopener"
                        style={{ 
                            color: 'pink',
                            fontSize: '20px'
                        }}
                        
                    >
                        LinkedIn
                    </Link>
                    
                </Box>    
                
                
                
            </Box>
        )
    }
}
