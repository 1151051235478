import React, { Component } from 'react'
import { Box, Typography, Paper} from '@material-ui/core'
import KeyboardArrowRightTwoToneIcon from '@material-ui/icons/KeyboardArrowRightTwoTone';

export default class About extends Component {
    render() {
        return (
            <Box style={{ alignContent: 'center'}}>
                
                
                <Paper 
                    style={{ 
                        backgroundColor: 'rgba(94, 139, 208, 0.93)', 
                        margin: '0.5em',
                        border: '3px inset #5EC4D0',
                        borderRadius: '25px' 
                    }}>
                    <Typography 
                        variant="h2" 
                        style={{ 
                            padding: '5px', 
                            fontFamily: 'monospace' ,
                            margin: '10px'
                        }}>
                        <KeyboardArrowRightTwoToneIcon fontSize="large" /> 
                        Welcome to my website!
                    </Typography>
            
            
                    <Typography 
                        variant="h5" 
                        style= {{ 
                            padding: '10px', 
                            fontFamily: 'monospace',
                            paddingBottom: '25px'
                        }}>
                        <KeyboardArrowRightTwoToneIcon fontSize='small'/> 
                        I am a Software Engineer based in Atlanta, Georgia. I primarily work with React.js, Node,js, MongoDB, Python, and Typescript. I am searching for a Junior Software Engineer position that utilizes these technologies. Below you will find my resume along with links to a few of my personal projects.  
                    </Typography>

                </Paper>
                
            </Box>
        )
    }
}
