import React, { Component } from 'react'
import { Box, Typography } from '@material-ui/core'

export default class MoreAbout extends Component {
    render() {
        return (
            <Box
                style={{
                border: '3px inset #5EC4D0',
                borderRadius: '25px',
                backgroundColor: 'rgba(94, 139, 208, 0.93)', 
                padding: '20px',
                textAlign: 'center',
                fontFamily: 'monospace',
                margin: '10px'
                }}
            >
                <Typography variant="h2"
                style={{
                    paddingBottom: '5px'
                }}
                >More About Me</Typography>
                <Typography variant="h5">
                    I’ve always been interested in computers, and how and why they work. In my younger days, I would hack games by reading and editing memory. Breaking down simple games and editing values to give me advantages is what  sparked my passion for code. I’m also intrigued by hardware, and I plan to expand my horizons by learning more about embedded systems. 

Apart from coding, I enjoy spending my free time outside; hiking, fishing, camping, and exploring nearby state parks and recreational areas. 
                </Typography>
                <small
                    style={{ 
                        padding: '10px',
                        display: 'block',
                        margin: 'auto'
                    }}
                >
                    Created With React
                </small>
            </Box>
        )
    }
}
